// Register.js
import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import PasswordStrengthMeter from './PasswordStrengthMeter'; // Import the PasswordStrengthMeter component
import * as Yup from 'yup';
import zxcvbn from 'zxcvbn';
import { validationSchema } from './ValidationSchema'; // Import the validation schema

const Register = () => {
    const navigate = useNavigate();

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        const result = zxcvbn(values.password);
        const score = result.score;

        // Check password strength
        if (score < 2) {
            setErrors({ password: 'Password must be at least moderate strength.' });
            setSubmitting(false);
            return;
        }

        setSubmitting(true);
        try {
            await axios.post('http://talon.kutznewtongrange.com:5000/api/signup', values);
            alert('Verification Email Sent');
            navigate('/Account'); // Redirect to the account page
        } catch (error) {
            setErrors({ server: 'Error creating user' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
    <div className="PaddedContainer">
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <h2 className="text-center">Register a New Account</h2>
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            password: '',
                            confirmPassword: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, values }) => (
                            <FormikForm>
                                <ErrorMessage
                                    name="server"
                                    component="div"
                                    className="alert alert-danger"
                                />
                                <Form.Group controlId="formBasicFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Field
                                        type="text"
                                        name="firstName"
                                        as={Form.Control}
                                        placeholder="Enter first name"
                                    />
                                    <ErrorMessage name="firstName" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="formBasicLastName" className="mt-3">
                                    <Form.Label>Last Name</Form.Label>
                                    <Field
                                        type="text"
                                        name="lastName"
                                        as={Form.Control}
                                        placeholder="Enter last name"
                                    />
                                    <ErrorMessage name="lastName" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail" className="mt-3">
                                    <Form.Label>Email address</Form.Label>
                                    <Field
                                        type="email"
                                        name="email"
                                        as={Form.Control}
                                        placeholder="Enter email"
                                    />
                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword" className="mt-3">
                                    <Form.Label>Password</Form.Label>
                                    <Field
                                        type="password"
                                        name="password"
                                        as={Form.Control}
                                        placeholder="Password"
                                    />
                                    <br/>
                                    <PasswordStrengthMeter password={values.password} /> {/* Add the password strength meter */}
                                    <ErrorMessage name="password" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="formBasicConfirmPassword" className="mt-3">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Field
                                        type="password"
                                        name="confirmPassword"
                                        as={Form.Control}
                                        placeholder="Confirm Password"
                                    />
                                    <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                                </Form.Group>

                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="mt-4 w-100"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Registering...' : 'Register'}
                                </Button>
                            </FormikForm>
                        )}
                    </Formik>
                    <div className="text-center mt-3">
                        <Link to="/Account">Already have an account? Login</Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    );
};

export default Register;

