import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function ResetPassword() {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://talon.kutznewtongrange.com:5000/api/reset-password', {
                token,
                newPassword
            });
            setMessage(response.data);
        } catch (error) {
            setMessage(error.response.data);
        }
    };

    const buttonStyle = {
        backgroundColor: '#2575fc',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1a5bbf',
        },
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                px: 2,
            }}
        >
            <Box
                sx={{
                    maxWidth: '500px',
                    width: '100%',
                    backgroundColor: 'white',
                    p: 3,
                    borderRadius: 1,
                    boxShadow: 3,
                }}
            >
                <Typography variant="h4" component="h1" align="center" mb={4}>
                    Reset Password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        type="password"
                        label="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <Button type="submit" variant="contained" sx={buttonStyle}>
                        Reset Password
                    </Button>
                </Box>
                {message && (
                    <Typography variant="body1" color="textSecondary" align="center" mt={2}>
                        {message}
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

export default ResetPassword;
